<template>
	<div
		ref="post_list_item_view"
		class="mt-50 size-px-14 pa-30-20 my-shorts"
	>
		<div class="title">{{ $language.shorts.title_my_cartel}} <!-- 가입 카르텔 --></div>
		<div class="mt-20 cartel">
			<ul>
				<li
					v-for="(cartel, c_index) in items"
					:key="'cartel_' + c_index"
					class="mb-15"
					@click="toList(cartel)"
				>
					<div class="justify-space-between gap-15 items-center">
						<div class="square-82 radius-20"><img :src="cartel.cartl_img_url" @error="$bus.$emit('onErrorImage', $event)" class="object-cover" :placeholder="cartel.cartl_name" :alt="cartel.cartl_name"/></div>
						<div class="flex-1">
							<div class="title overflow-hidden ellipsis">{{ cartel.cartl_name}}</div>
							<div class="mt-10"><span class="subject">{{ $language.shorts.title_my_shorts}} <!-- 등록 숏플 --></span> <span class="text">{{ cartel.reg_shrtple_count | makeComma}}</span></div>
							<div class="mt-10"><span class="subject">{{ $language.shorts.subject_ing}} <!-- 게시중 --></span> <span class="text">{{ cartel.bulletin_shrtple_count | makeComma}}</span></div>
						</div>
					</div>
				</li>
			</ul>
		</div>

		<ScrollPagination
			v-if="$refs.post_list_item_view"
			:body="$refs.post_list_item_view"
			:item_search="item_search"
			:max="max"
			:is_add="is_add"

			@change="getSearch"
		></ScrollPagination>
	</div>
</template>

<script>
import ScrollPagination from "@/components/ScrollPagination";
export default{
	name: 'MyShorts'
	, components: {ScrollPagination}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'MyShorts'
				, title: this.$language.shorts.title_my_shorts
				, not_footer: true
				, type: 'mypage'
				, from: 'myPage'
			}
			, items: []
			, item_search: {
				page_number: 1
				, pagerecnum: 10
			}
			, max: false
			, is_add: true
		}
	}
	, methods: {
		getData: async function(){
			try{
				this.is_add = false
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_my_shorts_cartel
					, data: {
						member_number: this.user.member_number
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})
				if(result.success){
					if(result.data.reg_shrtple_cartl_list.length > 0){
						if(this.items.length > 0){
							this.items = this.items.concat(result.data.reg_shrtple_cartl_list)
						}else{
							this.items = result.data.reg_shrtple_cartl_list
						}
						this.max = false
					}else{
						this.max = true
					}

				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_add = true
			}
		}
		, getSearch: function(page){
			this.item_search.page_number = page
			this.getData()
		}
		, toList: function(item){
			this.$bus.$emit('to', { name: 'MyShortsList', params: { idx: item.cartl_number}})
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>

<style>
.my-shorts > .title {
	color: var(--mafia-Blue, #171C61);
	font-family: Noto Sans CJK KR;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 100%; /* 16px */
}

.my-shorts > .cartel .title {
	color: var(--mafia-Blue, #333);
	font-family: Noto Sans CJK KR;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%; /* 16px */
}

.my-shorts > .cartel .subject {
	color: var(--dark-Gray02, #333);
	font-family: Noto Sans CJK KR;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%; /* 14px */
}
.my-shorts > .cartel .text {
	color: var(--gray01, #888);
	font-family: Open Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%; /* 14px */
}
</style>